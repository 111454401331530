export const noop = () => void 0;

export function now(): number {
  const time = Date.now();
  now.last = time > now.last ? time : now.last + 1;
  return now.last;
}

now.last = Date.now();

export const parseAppVersion = (version: string) =>
  version.split(".").map((v) => Number.parseInt(v, 10)) as [
    number,
    number,
    number,
  ];

// до 10_000 пишем как есть (726, 4334)
// при наведении или тапе на счетчик - отображаем сверху точное количество
// после 10к и до 1м округляем до Nk (10к, 76к, 123к, 879к)
// после 1м округляем до B.Nm (1.2m, 12.5m, 125.7m)
export const shortCount = (count: number): string => {
  if (count < 10_000) {
    return count.toString();
  }
  if (count < 1_000_000) {
    return `${Math.trunc(count / 1000).toString()} + K`;
  }
  return `${(Math.trunc(count / 100_000) / 10).toString()} + M`;
};
