import { addDefaultFormField } from "@base/components/pages/forms/edit/helpers";
import {
  FieldType,
  type FieldTypes,
  type FormItem,
  FormItemType,
} from "@base/models/forms/common";
import { TextFieldOptionType } from "@base/models/forms/form-fields/text";
import { StaticFormBodyStatus } from "@base/models/forms/static-form";
import { loadForm } from "@base/storage";
import { isFormItemFormField } from "@base/utils/type-guards";
import type { ValidationErrorDetail } from "@base/utils/validation";
import { type ParentProps, createContext } from "solid-js";
import { createStore, produce } from "solid-js/store";

interface ValidationProperties {
  name: string;
  description: string;
  items: Array<FormItem>;
  isFormValid: boolean;
  isSubmitClicked: boolean;
  bodyStatus: StaticFormBodyStatus;
}

interface FormInfo {
  name: string;
  description: string;
  bodyStatus: StaticFormBodyStatus;
}
interface ValidationMethods {
  addValidationError: (val: ValidationErrorDetail) => void;
  addNameField: (id: string | number, val: string) => void;
}

interface ValidationState extends ValidationProperties {}

const defaultFormData = loadForm();

const defaultItems: FormItem[] = defaultFormData?.items ?? [
  addDefaultFormField(FieldType.Text),
];
const [formsState, setFormsState] = createStore<ValidationProperties>({
  name: defaultFormData?.name ?? "",
  description: defaultFormData?.description ?? "",
  items: defaultItems,
  isFormValid: true,
  isSubmitClicked: false,
  bodyStatus: StaticFormBodyStatus.Unpublished,
});

export const useFormsMethods = () => {
  const updateName = (name: string) => {
    setFormsState({ name });
  };

  const initFormState = () => {
    const state = loadForm();
    if (state) {
      setFormsState(state);
    }
  };

  const updateDescription = (description: string) => {
    setFormsState({ description });
  };

  const setItems = (items: FormItem[]) => {
    setFormsState("items", items);
  };

  const pushItem = (item: FormItem) => {
    console.log(item);

    setFormsState("items", (items) => [...items, item]);
  };

  const swapItems = (fromIndex: number, toIndex: number) => {
    setFormsState("items", (items) => {
      const newItems = [...items];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      return newItems;
    });
  };

  const handleSubmitClick = (value: boolean) => {
    setFormsState("isSubmitClicked", value);
  };

  const deleteItem = (index: number) => {
    setFormsState("items", (items) => items.filter((_, i) => i !== index));
  };

  const updateNameItem = (index: number, name: string) => {
    setFormsState("items", index, "formItemData", "data", "text", name);
  };

  const toggleItemRequired = (index: number, isChecked: boolean) => {
    setFormsState(
      "items",
      produce((items) => {
        if (isFormItemFormField(items[index])) {
          items[index].formItemData.data.formFieldData.data.required =
            isChecked;
        }
      }),
    );
  };

  const updateOptionField = <T extends FieldTypes>(
    index: number,
    data: Partial<T>,
    fieldType: FieldType,
  ) => {
    setFormsState("items", index, "formItemData", "data", (field) => {
      if ("formFieldData" in field && field.formFieldData.type === fieldType) {
        return {
          ...field,
          formFieldData: {
            ...field.formFieldData,
            data: {
              ...field.formFieldData.data,
              ...data,
            },
          },
        };
      }
      return field;
    });
  };

  const updateFormInfo = (formInfo: FormInfo) => {
    setFormsState(formInfo);
  };

  const toggleIsFormValid = (value: boolean) => {
    setFormsState("isFormValid", value);
  };

  const deleteOptionField = (
    index: number,
    option: string,
    fieldType: FieldType,
  ) => {
    setFormsState("items", index, "formItemData", "data", (field) => {
      if ("formFieldData" in field && field.formFieldData.type === fieldType) {
        if ("options" in field.formFieldData.data) {
          return {
            ...field,
            formFieldData: {
              ...field.formFieldData,
              data: {
                ...field.formFieldData.data,
                options: field.formFieldData.data.options.filter(
                  (item) => item !== option,
                ),
              },
            },
          };
        }
      }
      return field;
    });
  };

  const resetForm = () => {
    setFormsState({
      name: "",
      description: "",
      items: [
        {
          formItemData: {
            data: {
              formFieldData: {
                data: {
                  min: 0,
                  max: 500,
                  defaultValue: null,
                  option: {
                    type: TextFieldOptionType.Any,
                  },
                  required: false,
                },
                type: FieldType.Text,
              },
              name: "",
              text: "",
            },
            type: FormItemType.FormField,
          },
        },
      ],
      isFormValid: true,
      isSubmitClicked: false,
      bodyStatus: StaticFormBodyStatus.Unpublished,
    });
  };
  return {
    updateName,
    updateDescription,
    pushItem,
    swapItems,
    deleteItem,
    updateNameItem,
    toggleIsFormValid,
    toggleItemRequired,
    resetForm,
    handleSubmitClick,
    updateOptionField,
    deleteOptionField,
    setItems,
    updateFormInfo,
    initFormState,
  };
};

const formsContext = createContext<ValidationState>(formsState);

const FormsProvider = (props: ParentProps) => {
  return (
    <formsContext.Provider value={formsState}>
      {props.children}
    </formsContext.Provider>
  );
};

export {
  type ValidationProperties,
  type ValidationMethods,
  FormsProvider,
  formsContext,
};
