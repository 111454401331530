import { Alert } from "@base/components/custom/alerts/Alert";
import { init } from "@base/utils/init-user";
import Main from "@comp/blocks/Main";
import { isDev } from "@libfunc/env";
import { createComponent, render } from "solid-js/web";
import { ALERTS, APP } from "./consts/elements";
import { metricsService } from "./metrics";

// import("@base/css/dynamic/index.css");

const userId = init();

const app = document.getElementById(APP)!;
render(() => createComponent(Main, {}), app);

const alertComponent = () => createComponent(Alert, {});
const alerts = document.getElementById(ALERTS)!;
render(alertComponent, alerts);

Promise.resolve().then(() => metricsService.subscribe());

if (!isDev) {
  import("./utils/sentry").then(({ sentryInit }) => sentryInit(userId));
}
