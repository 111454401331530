interface Engine {
  chrome: boolean;
  chromium: boolean;
  safari: boolean;
  webkit: boolean;
  firefox: boolean;
  android: boolean;
  iphone: boolean;
}

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#avoiding_user_agent_detection
// https://hacks.mozilla.org/2013/09/user-agent-detection-history-and-checklist/
// All: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7)
// Edge: AppleWebKit/537.36 (KHTML, like Gecko) Chrome/110.0.0.0 Safari/537.36 Edg/110.0.1587.50
// Chrome: AppleWebKit/537.36 (KHTML, like Gecko) Chrome/110.0.0.0 Safari/537.36
// Safari: AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Safari/605.1.15
// Firefox: Gecko/20100101 Firefox/111.0
const getEngine = (): Engine => {
  const { userAgent, vendor, sayswho } = navigator;

  const engine: Engine = {
    chrome: false,
    chromium: false,
    safari: false,
    webkit: false,
    firefox: false,
    android: false,
    iphone: false,
  };

  if (sayswho?.includes("Chrome")) {
    engine.chrome = true;
  }

  if (vendor.includes("Google")) {
    engine.chrome = true;
  }

  if (vendor.includes("Apple")) {
    engine.safari = true;
  }

  const chrome = userAgent.includes("Chrome/");
  const chromium = userAgent.includes("Chromium/");

  if (userAgent.includes("Safari/") && !chrome && !chromium) {
    engine.safari = true;
  }

  if (userAgent.includes("Edg/")) {
    engine.chromium = true;
  }

  if (chrome) {
    engine.chrome = true;
  }
  if (chromium) {
    engine.chromium = true;
  }

  if (userAgent.includes("AppleWebKit/")) {
    engine.webkit = true;
  }

  if (userAgent.includes("Firefox/")) {
    engine.firefox = true;
  }

  if (userAgent.includes("Android")) {
    engine.android = true;
  }

  if (userAgent.includes("iPhone")) {
    engine.iphone = true;
  }

  if ("webkitLineBreak" in document.documentElement.style) {
    engine.webkit = true;
  }

  return engine;
};

export const ENGINE = getEngine();
