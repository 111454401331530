import {
  type FormItem,
  type FormItemFormField,
  FormItemType,
} from "@base/models/forms/common";

export function isFormItemFormField(
  item: FormItem,
): item is { formItemData: FormItemFormField } {
  return item.formItemData.type === FormItemType.FormField;
}

export const getItemFornField = () => {};
