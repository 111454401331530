import { FormsProvider } from "@base/models/forms-context";
import { Suspense } from "solid-js";
import type { JSX } from "solid-js";
import { Loader } from "../custom/Loader";

type WithSuspenseOptions = {
  validationProvider?: boolean;
};

export const WithSuspense = (
  component: () => JSX.Element,
  options?: WithSuspenseOptions,
) => {
  const { validationProvider = false } = options || {};

  return () => (
    <Suspense fallback={<Loader reason="suspense" />}>
      {validationProvider ? (
        <FormsProvider>{component()}</FormsProvider>
      ) : (
        component()
      )}
    </Suspense>
  );
};
