import { apiUrl, isDev, isTesting, orientation } from "@libfunc/env";
import type { AppId } from "@libfunc/types";
import { uid } from "@libfunc/utils/app-id";
import { ENGINE } from "@libfunc/utils/get-engine";
import {
  isOffscreenCanvas,
  isSharedWorkers,
  isTouchDevice,
  isTouchMedia,
  isTouchPoints,
  wasmFeatures,
  wasmSupported,
} from "@libfunc/utils/supported-fetures";
import workerUrl from "@libfunc/workers/metrics.js?sharedworker&url";
import type { Metric as WebVitalsMetric } from "web-vitals";
import { onCLS, onFCP, onINP, onLCP, onTTFB } from "web-vitals";
import { createWs, sendMessage } from "../libfunc/utils/websocket";
import { METRICS_PATH } from "./consts";
import { appId } from "./storage";
import { isPhone } from "./utils/helpers";

// import workerContent from "metrics.js?raw";
// import inlineWorker from "metrics.js?sharedworker&inline";

export interface MetricsData {
  appId: AppId;
  instanceId: AppId;
  metrics?: MetricsService["metrics"];
  changeLocations?: MetricsService["changeLocations"];
  errors?: MetricsService["errors"];
}

export interface ErrorData {
  error: unknown;
}

export interface MetricData {
  event: string;
  [key: string]: unknown;
}

export interface ChangeLocation {
  pathname: string;
  search: string;
  hash: string;
  host: string;
}

export type Metric<T> = { date: Date } & T;

const INTERVAL_MS = 10_000;

class MetricsService implements MetricsData {
  metrics: Metric<MetricData>[] = [];
  errors: Metric<ErrorData>[] = [];
  changeLocations: Metric<ChangeLocation>[] = [];

  private socket: WebSocket | null = null;
  private worker: SharedWorker | null = null;
  readonly appId: AppId;
  readonly instanceId: AppId;
  private interval: number | null = null;
  private isWorker = isSharedWorkers;
  private errorCount = 0;
  private intervalMs = INTERVAL_MS;

  constructor() {
    const instanceId = uid();

    this.appId = appId;
    this.instanceId = instanceId;
    this.startInterval();
    window.metricsSend = () => this.send();
  }

  async subscribe() {
    window.onerror = (message, url, line, col, error) => {
      const msg = typeof message === "string" ? message : message.type;
      this.addError({ error, msg, url, line, col });
    };
    window.addEventListener("error", (event) => {
      this.addError(event.error);
    });
    window.addEventListener("popstate", () => {
      const { pathname, search, hash, host } = window.location;
      const data: ChangeLocation = { host, pathname, search, hash };
      this.addChangeLocations(data);
    });
    // https://developer.chrome.com/blog/page-lifecycle-api/
    window.addEventListener(
      "visibilitychange",
      () => {
        this.add({
          event: "visibilitychange",
          visibilityState: document.visibilityState,
        });
        this.sendOnPageHide();
      },
      { passive: true, capture: true },
    );
    window.addEventListener(
      "pagehide",
      (event) => {
        this.add({
          event: "pagehide",
          persisted: event.persisted,
        });
        this.sendOnPageHide();
      },
      { passive: true, capture: true },
    );

    const { outerWidth, innerWidth, innerHeight, outerHeight } = window;
    const inner = window.innerWidth;
    const html = document.querySelector("html")!;
    const offset = html.offsetWidth;
    const client = html.clientWidth;
    const scroll = html.scrollWidth;
    const htmlWidth: {
      inner: number;
      outer?: number;
      offset?: number;
      client?: number;
      scroll?: number;
    } = {
      inner,
    };
    if (inner !== window.outerWidth) {
      htmlWidth.outer = window.outerWidth;
    }
    if (inner !== offset) {
      htmlWidth.offset = offset;
    }
    if (inner !== client) {
      htmlWidth.client = client;
    }
    if (inner !== scroll) {
      htmlWidth.scroll = scroll;
    }
    const { width, height, availHeight, availWidth } = screen;
    const json = window.performance.toJSON();

    const page = `${window.location.pathname}${window.location.search}`;

    this.add({
      event: "device-info",
      performance: json ? json : undefined,
      device: {
        outerWidth,
        innerWidth,
        innerHeight,
        outerHeight,
        width,
        height,
        availHeight,
        availWidth,
        htmlWidth,
      },
      features: {
        isOffscreenCanvas,
        isTouchDevice,
        isTouchMedia,
        isTouchPoints,
        isSharedWorkers,
      },
      isMobile: isPhone(),
      orientation,
      engine: ENGINE,
      page,
    });

    const wasm = wasmSupported
      ? {
          simd: (await wasmFeatures()).simd,
        }
      : null;

    this.add({
      event: "wasm",
      wasm,
    });

    // https://web.dev/vitals/
    const onPerfEntry =
      (val: number) =>
      (metric: WebVitalsMetric): void => {
        this.add({
          event: "web-vitals",
          metric,
        });

        if (!isDev && metric.value > val) {
          console.warn(metric.name, metric.value, val);
        }
      };
    // 0.1
    onCLS(onPerfEntry(0.05));
    // 100ms
    onINP(onPerfEntry(10));
    // 1.8sec or 1800
    onFCP(onPerfEntry(1000));
    // 2.5sec or 2500
    onLCP(onPerfEntry(800));
    // 600ms
    onTTFB(onPerfEntry(400));
  }

  add(data: { event: string; [key: string]: unknown }): void {
    this.metrics.push({
      ...data,
      date: new Date(),
    });
  }

  addError(error: unknown): void {
    this.errors.push({
      date: new Date(),
      error:
        error instanceof Error
          ? {
              name: error.name,
              message: error.message,
              stack: error.stack,
            }
          : error,
    });
  }

  addChangeLocations(data: ChangeLocation) {
    const date = new Date();
    this.changeLocations.push({ ...data, date });
  }

  onError = () => {
    const isPowerOfTwo = this.errorCount !== 0 && this.errorCount % 2 === 0;
    if (isPowerOfTwo) {
      clearInterval(this.interval!);
      this.intervalMs = this.intervalMs + 5000;
      this.startInterval();
    }

    this.errorCount = this.errorCount + 1;
  };

  private getData(): MetricsData | null {
    const isMetrics = this.metrics.length > 0;
    const isChangeLocations = this.changeLocations.length > 0;
    const isErrors = this.errors.length > 0;

    if (!isMetrics && !isChangeLocations && !isErrors) {
      return null;
    }

    const data: MetricsData = {
      appId: this.appId,
      instanceId: this.instanceId,
    };

    if (isMetrics) {
      data.metrics = this.metrics;
    }
    if (isChangeLocations) {
      data.changeLocations = this.changeLocations;
    }

    if (isErrors) {
      data.errors = this.errors;
    }

    return data;
  }

  private isWebsocket() {
    const closed: number[] = [WebSocket.CLOSED, WebSocket.CLOSING];
    return this.socket && !closed.includes(this.socket.readyState);
  }

  private async send() {
    const data = this.getData();

    if (!data) {
      return;
    }

    if (this.isWorker) {
      try {
        if (!this.worker) {
          const uri = isDev
            ? import.meta.resolve("./workers/metrics.js")
            : new URL(workerUrl, import.meta.url);

          // this.worker = new inlineWorker();

          // this.worker = new SharedWorker(url, {
          //   name: "metrics",
          //   credentials: "include",
          //   type: "module",
          // });

          const res = await fetch(uri, {
            credentials: "omit",
            mode: "cors",
            keepalive: true,
            cache: "default",
            priority: "low",
          });
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          const workerContent = await res.text();

          const blob = new Blob([workerContent], { type: "text/javascript" });
          const url = URL.createObjectURL(blob);
          const wrkr = new SharedWorker(url, {
            name: "metrics",
            credentials: "include",
            type: "module",
          });
          this.worker = wrkr;

          const handleError = (error: ErrorEvent) => {
            console.error(error);
            this.onError();
            this.isWorker = false;
          };
          // this.worker.addEventListener("error", handleError);
          this.worker.onerror = handleError;

          this.worker.port.onmessage = (
            event: MessageEvent<{ status: string }>,
          ) => {
            if (event.data.status === "connected") {
              // URL.revokeObjectURL(url);
            } else {
              console.warn(event.data);
            }
          };

          this.worker.port.start();
          const apiPrefix = apiUrl || window.location.origin;
          this.worker.port.postMessage({
            init: { apiPrefix, apiMode: isTesting ? "cors" : "same-origin" },
          });
        }

        this.worker.port.postMessage({ metrics: JSON.stringify(data) });
      } catch (error) {
        console.error(error);
        this.onError();
        this.isWorker = false;
        return;
      }
    } else {
      const sendData = {
        type: "metrics",
        data,
      };

      if (this.isWebsocket()) {
        sendMessage(this.socket!, sendData);
      } else {
        try {
          const socket = await createWs("metrics");
          this.socket = socket;
          sendMessage(this.socket, sendData);
        } catch (error) {
          console.error(error);
          this.onError();
        }
      }
    }

    this.clearMetrics();
  }

  private clearMetrics() {
    this.metrics = [];
    this.changeLocations = [];
    this.errors = [];
    if (this.errorCount > 0) {
      this.errorCount = 0;
      this.intervalMs = INTERVAL_MS;
    }
  }

  sendOnPageHide() {
    const data = this.getData();

    if (!data) {
      return;
    }

    if (this.isWorker && this.worker) {
      this.worker.port.postMessage(JSON.stringify(data));
      return;
    }

    const closed: number[] = [WebSocket.CLOSED, WebSocket.CLOSING];

    // this.socket
    if (!this.socket || closed.includes(this.socket.readyState)) {
      navigator.sendBeacon(METRICS_PATH, JSON.stringify(data));
    } else {
      const sendData = {
        type: "metrics",
        data,
      };

      sendMessage(this.socket, sendData);
    }
  }

  private startInterval() {
    setTimeout(() => {
      this.send();
    }, 500);
    this.interval = setInterval(() => {
      this.send();
    }, this.intervalMs) as unknown as number;
  }
}

export const metricsService = new MetricsService();
