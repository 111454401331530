import type { PhoneOrEmail, UserEntry } from "@base/models/user";
import type { AppId } from "@libfunc/types";

import { get, post, put } from "./index";

const PATH = "auth/";

interface CheckAuthRes {
  isAuth: boolean;
  user?: UserEntry;
}

export const checkAuth = () => get<CheckAuthRes>(`${PATH}check-auth`);

export const logout = () => post(`${PATH}logout`, null);

interface LoginResBody {
  user?: UserEntry;
  twoFactor: string;
}

export const login = (
  user: PhoneOrEmail | { username: string },
  password: { isEncrypt: boolean; password: string },
  appId: AppId,
) => post<LoginResBody>(`${PATH}login`, { user, password, appId });

export const checkTwoFactor = (token: string, code: string) =>
  post<LoginResBody>(`${PATH}two-factor`, { token, code });

export const signup = (body: { user: PhoneOrEmail }) =>
  post<string>(`${PATH}signup`, {
    user: body.user,
  });

interface SignupResBody {
  user: UserEntry;
}

export const signupComplete = (body: {
  user: PhoneOrEmail;
  password: { isEncrypt: boolean; password: string };
  code: string;
  appId: AppId;
}) =>
  post<SignupResBody>(`${PATH}signup-complete`, {
    user: body.user,
    password: body.password,
    code: body.code,
    appId: body.appId,
  });

export const loginWithGoogle = (
  params: {
    credential: string;
    select_by:
      | "btn"
      | "auto"
      | "btn_confirm"
      | "btn_add_session"
      | "btn_confirm_add_session";
  },
  appId: AppId,
) =>
  post<LoginResBody>(`${PATH}login-with-google`, {
    googleParams: params,
    appId,
  });

interface Password {
  isEncrypt: boolean;
  password: string;
}

export const changePassword = (password: Password) =>
  put(`${PATH}password`, { password });

interface ResetPwdResBody {
  code?: string;
  twoFactor: string;
  token: string;
}

export const resetPassword = (user: PhoneOrEmail) =>
  post<ResetPwdResBody>(`${PATH}reset-password`, { user });

interface ResetPwdConfirmReq {
  user: PhoneOrEmail;
  password: Password;
  code: string;
  token: string;
  code2fa?: string;
}

export const resetPasswordConfirm = (body: ResetPwdConfirmReq) =>
  post(`${PATH}reset-password-confirm`, body);

export const createTotp = () =>
  get<{ svg: string; secret: string }>(`${PATH}create-totp`);

export const confirm2fa = (secret: string, code: string) =>
  post(`${PATH}confirm-2fa`, { secret, code });
