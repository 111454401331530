import { AlertType, closeMessage, messages } from "@base/store";
import styles from "@comp/styles.module.css";
import { CloseIcon } from "@icons";
import { For } from "solid-js";
import classes from "./alerts.module.css";

export const Alert = () => {
  const onClick = (id: number) => () => {
    closeMessage(id);
  };

  const alertTypeClasses = {
    [AlertType.Info]: classes.alertInfo,
    [AlertType.Success]: classes.alertSuccess,
    [AlertType.Warning]: classes.alertWarning,
    [AlertType.Error]: classes.alertError,
  };

  return (
    <For each={messages}>
      {(item) => (
        <div class={`${classes.alert} ${alertTypeClasses[item.type]}`}>
          <div class={`${styles.row} ${classes.alertHead}`}>
            {item.type}
            <button
              onClick={onClick(item.id)}
              type="button"
              class={`${styles.icon} ${styles.outline}`}
            >
              <CloseIcon />
            </button>
          </div>
          <p class={classes.alertMsg}>{item.message}</p>
        </div>
      )}
    </For>
  );
};
