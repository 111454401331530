import { changeIsOpenModal } from "@base/utils/modal-menu";
import styles from "@comp/styles.module.css";
import { CloseIcon, MiMenu } from "@icons";
import type { Accessor } from "solid-js";
import { Show } from "solid-js";

export const MenuButton = (props: { isModal: Accessor<boolean> }) => {
  const onClick = () => {
    changeIsOpenModal();
  };

  return (
    <button
      type="button"
      id="open-modal"
      onClick={onClick}
      title={$.global.open_nav_menu}
      aria-label={$.global.open_nav_menu}
      class={styles.icon}
    >
      <Show when={!props.isModal()} fallback={<CloseIcon />}>
        <MiMenu />
      </Show>
    </button>
  );
};
