import type { JSX, ParentProps } from "solid-js";
import { Show } from "solid-js";

import { Loader } from "../custom/Loader";

interface ContentProps {
  class?: string;
  title?: string;
  loading?: boolean;
  bodyStyle?: JSX.CSSProperties | string;
}

const Content = (props: ParentProps<ContentProps>) => {
  return (
    <Show when={!props.loading} fallback={<Loader reason="content-loading" />}>
      <Show when={props.title}>
        <h1 id="page-header">{props.title}</h1>
      </Show>
      <div id="page-body" class={props.class} style={props.bodyStyle}>
        {props.children}
      </div>
    </Show>
  );
};

export default Content;
