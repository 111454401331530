import type { AppId } from "@libfunc/types";
import { now } from "@libfunc/utils/helpers";

const rand = (): string => {
  const arr = new Uint32Array(2);
  crypto.getRandomValues(arr);
  const view = new DataView(arr.buffer);
  let uint64 = view.getBigUint64(0, true);
  uint64 = BigInt.asUintN(64, uint64);
  return uint64.toString();
};

export const uid = (): AppId => {
  const n = now();
  const r = rand();

  return [n, r] as AppId;
};
