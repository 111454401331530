import type { RouteDefinition } from "@solidjs/router";
import type { Component } from "solid-js";
import { lazy } from "solid-js";

import { preloadFormData } from "@comp/pages/forms/fill/FormResources";
import { WithSuspense } from "./WithSuspense";

const Signup = lazy(() => import("@comp/pages/auth/Signup"));
const Login = lazy(() => import("@comp/pages/auth/Login"));
const GoogleRedirect = lazy(() => import("@comp/pages/auth/GoogleRedirect"));
const PasswordReset = lazy(() => import("@comp/pages/auth/PasswordReset"));
const Profile = lazy(() => import("@comp/profile/Profile"));
const Settings = lazy(() => import("@comp/profile/settings/Settings"));
const User = lazy(() => import("@comp/pages/users/User"));
const Home = lazy(() => import("@comp/pages/home/Home"));
const About = lazy(() => import("@comp/pages/others/About"));
const Docs = lazy(() => import("@comp/pages/others/Docs"));
const Policy = lazy(() => import("@comp/pages/others/Policy"));
const Terms = lazy(() => import("@comp/pages/others/Terms"));
const DeviceInfo = lazy(
  () => import("@base/components/pages/others/device-info/DeviceInfo"),
);
const FormsPage = lazy(() => import("@comp/pages/forms/FormsPage"));
const FormEdit = lazy(() => import("@comp/pages/forms/edit/FormEdit"));
const NewForm = lazy(() => import("@comp/pages/forms/edit/NewForm"));
const Form = lazy(() => import("@comp/pages/forms/fill/Form"));
const FormShow = lazy(() => import("@comp/pages/forms/votes/VotesFormPage"));
const NotFound = lazy(() => import("@comp/pages/errors/404"));

const r = (path: string, component: Component): RouteDefinition => ({
  path,
  component,
});

export const routes: RouteDefinition[] = [
  r("/", WithSuspense(Home)),
  r("/reset-password", WithSuspense(PasswordReset)),
  r("/signup", WithSuspense(Signup)),
  r("/login", WithSuspense(Login)),
  r("/login-with-google", WithSuspense(GoogleRedirect)),
  r("/u/:id", WithSuspense(User)),
  {
    path: "/f",
    children: [
      r("/", WithSuspense(FormsPage)),
      r("/new", WithSuspense(NewForm)),
      {
        path: "/:id",
        component: Form,
        load: preloadFormData,
      },
      r("/:id/edit", WithSuspense(FormEdit)),
      r("/:id/show", WithSuspense(FormShow)),
    ],
  },
  // r("/invite/:id", Invite),
  // r("/search", Search),
  r("/profile", WithSuspense(Profile)),
  r("/settings", WithSuspense(Settings)),
  r("/about", WithSuspense(About)),
  r("/docs", WithSuspense(Docs)),
  r("/privacy-policy", WithSuspense(Policy)),
  r("/terms-of-service", WithSuspense(Terms)),
  r("/device-info", WithSuspense(DeviceInfo)),
  r("/404", WithSuspense(NotFound)),
];
