import type { FormItem, FormItemDataTypes } from "@base/models/forms/common";
import { FieldType, FormItemType } from "@base/models/forms/common";
import { getDefaults } from "@base/models/forms/form-fields";

export enum PanelStackTypes {
  AddField = 0,
  AddContent = 1,
}

export const addDefaultFormField = (formFieldType: FieldType): FormItem => {
  return {
    formItemData: {
      // id: uid(),
      type: FormItemType.FormField,
      data: {
        text: "",
        // TODO: можно генерировать name на бекенде, так как очередь известна
        name: "", // `formField.${index}`,
        formFieldData: getDefaults(formFieldType),
      },
    },
  };
};

export const addDefaultMarkdown: () => FormItem = () => ({
  formItemData: {
    type: FormItemType.Markdown,
    data: {
      text: "",
    },
  },
});

export const addDefaultImage: () => FormItem = () => ({
  formItemData: {
    type: FormItemType.Image,
    data: {
      url: "",
      text: "",
      width: 600,
      height: 600,
    },
  },
});

const formFieldName = {
  [FieldType.Select]: $.forms.types.select,
  [FieldType.MultipleSelect]: $.forms.types.multiple,
  [FieldType.Number]: $.forms.types.number,
  [FieldType.Text]: $.forms.types.text,
  // [FieldType.Date]: $.forms.types.date,
  // [FieldType.File]: $.forms.types.file,
  // [FieldType.Time]: $.forms.types.time,
};

export const getFormItemTypeName = (data: FormItemDataTypes): string => {
  switch (data.type) {
    case FormItemType.Markdown:
      return $.forms.markdown;
    case FormItemType.Image:
      return $.forms.image;
    case FormItemType.Button:
      return $.forms.button;
    case FormItemType.FormField: {
      return formFieldName[data.data.formFieldData.type];
    }
  }
};
