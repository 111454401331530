import { ProgressIcon } from "@icons";
import { onCleanup, onMount } from "solid-js";

export const Loader = (props: { reason: string }) => {
  onMount(() => {
    console.time(props.reason);
  });
  onCleanup(() => {
    console.timeEnd(props.reason);
  });
  return (
    <div
      style={{
        animation: "rotation 2s infinite linear",
        width: "fit-content",
        "align-self": "center",
      }}
    >
      <ProgressIcon />
    </div>
  );
};
