import { MAX_PHONE_WIDTH } from "@base/consts";
import type { UserEntry } from "@base/models/user";
import { isDev } from "@libfunc/env";

export const isPhone = () =>
  (window.innerWidth || window.screen.width) <= MAX_PHONE_WIDTH;

// firstname lastname
export const getFullName = (user: {
  firstname?: string;
  lastname?: string;
}): string => {
  const { firstname, lastname } = user;
  let name: string;
  if (!lastname) {
    name = firstname ?? "";
  } else if (!firstname) {
    name = lastname;
  } else {
    name = `${firstname} ${lastname}`;
  }
  return name.trim();
};

// firstname lastname or @username or email or id
export const showUser = (user: UserEntry) => {
  return (
    getFullName({
      firstname: user.firstname,
      lastname: user.lastname,
    }) ||
    (user.username && `@${user.username}`) ||
    (user.email && `${$.user.roles.user} ${user.email}`) ||
    `${$.user.roles.user} ${user.id}`
  );
};

export const changeTitle = (title: string) => {
  const appTitle = isDev ? "LOCAL Form.Love" : "Form.Love";
  document.title = title ? `${title} | ${appTitle}` : appTitle;
};
