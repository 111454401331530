import { logout } from "@api/auth";
import { errorHandle } from "@base/api";
import type { UserEntry } from "@base/models/user";
import { now } from "@libfunc/utils/helpers";
import { createContext } from "solid-js";
import { createStore } from "solid-js/store";
import { initUser } from "./root-data";
import { loadUser, saveUser } from "./storage";

// store user
const initialAppState = {
  user: initUser ?? loadUser(),
};

const [state, setState] = createStore(initialAppState);

export const appStoreMethods = {
  setUser: (user: UserEntry | null) => {
    setState({ user });
    saveUser(user);
  },
  logout: () => {
    saveUser(null);
    setState("user", null);
    logout().catch(errorHandle);
  },
};

export const userStoreContext = createContext<typeof state>(state);

// store forms

// store message
export enum AlertType {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

interface AlertProps {
  message: string;
  type: AlertType;
  id: number;
}

const defaultMessages = [] as AlertProps[];

const messagesStore = createStore(defaultMessages);
export const messages = messagesStore[0];
const setMessages = messagesStore[1];

export const addMessage = (
  message: string,
  type: AlertType = AlertType.Info,
  seconds = 4000,
) => {
  const id = now();
  setMessages((old) => {
    return [...old, { message, type, id }];
  });
  // remove message after seconds
  setTimeout(() => {
    setMessages((old) => {
      if (old.length > 0) {
        const n = old.filter((item) => item.id !== id);
        return n;
      }
      return [];
    });
  }, seconds);
};

export const closeMessage = (id: number) => {
  setMessages((old) => {
    if (old.length > 0) {
      const n = old.filter((item) => item.id !== id);
      return n;
    }
    return [];
  });
};
